:root {
  --margin-left-menu: 260px;
  --background-main: #1F1F1F;
  --color-main: #BB162B;
  --color-main-gray: #7E8083;
  --color-sub-light-gray: #E4E5E6;
  --color-sub-medium-gray: #C4C6C8;
  --color-sub-text-gray: #58595B;
  --color-button-hover: #3040ba;
  --color-red: #b50000;
  --color-selected-item-light: #bbc8fc;
  --color-selected-item-dark: #7481b3;
}

div#root {
  height: 100%;
}

// h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, h6 {
//   font-family: "Muli", sans-serif, Georgia, "Times New Roman", Times, serif;
// }

.navigation {
  font-family: "Muli", Georgia, "Times New Roman", Times, sans-serif;
}

.header-navbar {
  font-family: "Muli", Georgia, "Times New Roman", Times, sans-serif;
}

.bg-42maru {
  background-color: var(--background-main);
}

.btn-bg-white {
  background-color: #ffffff;
}

.alert.alert-danger {
  border: none;
  border-left: 4px solid #BB162B;
  margin-bottom: 15px;
  background-color: rgba(255, 79, 129, 0.1);
}

.alert-danger {
  color: #BB162B;
  background-color: #fdcdd2 !important;
  border-color: #ffcedc;
}

.alert.alert-success {
  border: none;
  border-left: 4px solid #2dde98;
  margin-bottom: 15px;
  background-color: rgba(45, 222, 152, 0.1);
}

.alert-success {
  color: #17734f;
  background-color: #d5f8ea !important;
  border-color: #c4f6e2;
}

a.no-decorator {
  color: inherit;
  text-decoration: none;
}

//.ellipsis {
//  display: block;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//}

.dropdown-menu.dropdown-menu-right {
  right: 1px !important;
}


.block-ui-container {
  .block-ui-overlay {
    opacity: 0.5;
  }

  .block-ui-message-container {

    .block-ui-message {
      color: #967ADC;
      font-size: 1.3em;

      .loading-bullet {
        color: #967ADC;
        font-size: 1.2em;
      }
    }

    //top: 50vh !important;
  }
}

// timeline css

#timeline {
  .timeline-item-arrow-info {
    &:before {
      border-right-color: #3BAFDA !important;
    }
  }

  .timeline-item-arrow-success {
    &:before {
      border-right-color: #37BC9B !important;
    }
  }

  .timeline-item-arrow-danger {
    &:before {
      border-right-color: #DA4453 !important;
    }
  }
}

// nav

.nav-no-border-radius {
  border-radius: 0 !important;
}

td.td-sm {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

td.ellipsis {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-text.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip-inner {
  max-width: 100% !important;
}

// recharts custom b
.recharts-text {
  tspan {
    font-size: 0.8em;
  }
}

div.file-drop-zone {
  border: 2px dashed #967ADC;
  background: #F3F3F3;
}

mark {
  background-color: #AEFFD5;
}

.fixed-content-height {
  height: calc(100vh - 14rem);
}

.fixed-content-min-height {
  min-height: calc(100vh - 15rem);
}

.fixed-tab-content-height {
  height: calc(100vh - 20rem);
}

.card-highlight {
  background: #ABDDEF;
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

@mixin vh-x-list {
  @for $i from 1 through 100 {
    .vh-#{$i} {
      height: #{$i}vh;
    }
  }
}

@include vh-x-list;

.spinner-border-xs {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em;
  vertical-align: middle !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;

  :hover, :focus {
    color: var(--color-main);
    text-decoration: none;
  }
}

.table-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #98a6ad;
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 1.2rem;

  :hover, :focus {
    color: var(--color-main);
    text-decoration: none;
  }
}

.table-filter-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.width {
  &-350 {
    width: 350px;
  }
}

.height {
  &-55 {
    height: 55px;
  }

  &-250 {
    height: 250px;
  }
}

.block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: #f1f3fa;
  z-index: 9999;
}

.preloader {
  position: fixed;
  top: 0;
  left: var(--margin-left-menu);
  right: 0;
  bottom: 0;
  //background-color: #f1f3fa;
  z-index: 9999;
}

@media (max-width: 575.98px) {
  .preloader {
    left: 0;
  }
}

.application-selector-btn {
  display: block;
  width: 100%;
  padding: 10px 35px;
  background-color: var(--color-main);
  //border: 0;
  border: none;
  color: white;
  text-align: left;
  font-size: 1rem;
}

.application-selector-item {
  min-width: 180px;
  display: block;
  line-height: 30px;

  &.selected {
    background-color: var(--color-selected-item-dark);
    color: white;
  }
}

.profile-box {
  //border-radius: 5px;
  //padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px 0 0;
  position: fixed !important;
  left: 0;
  bottom: 0;
  background-color: #313a46;
  //background-color: #7e87f6;
  //background-color: #6c757d;
  //background-color: white;
  width: var(--margin-left-menu);

  .dropdown-toggle {
    &:after {
      content: none;
    }
  }

  .profile-btn {
    border-radius: 0;
    text-align: left;
    box-shadow: none;

    .avatar {
      vertical-align: middle;
      margin-right: 7px;
    }

    i {
      padding-top: 2px;
    }
  }
}

canvas.avatar {
  border-radius: 50%;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.none-background-image {
  background-image: none !important;
}

.font-grey {
  color: #6c757d;
}

.ellipsis {
  display: inline-block;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-30 {
  font-size: 30px;
}

.font-20{
  font-size: 20px;
}

.font-24{
  font-size: 24px;
}

.height-130 {
  height: 130px;
}

.left-side-menu {
  background: var(--background-main);
  width: var(--margin-left-menu);
}

.content-page {
  margin-left: var(--margin-left-menu);
}

.application-selector-btn {
  background-color: #2a328d;
}

.gray {
  color: gray;
}

.capitalize {
  text-transform: capitalize;
}

.col-container {
  display: table; /* Make the container element behave like a table */
  width: 100%; /* Set full-width to expand the whole page */
}

.col {
  display: table-cell; /* Make elements inside the container behave like table cells */
}
